import { useEffect, useState } from 'react';

export function useGetElementRects({ elementRef }) {
  const [itemContainerLayoutChange, setItemContainerLayoutChange] = useState({
    elementRect: {
      scrollWidth: 0,
      clientWidth: 0,
      clientHeight: 0,
    },
    timeChanged: 0,
  });

  useEffect(() => {
    if (elementRef?.current) {
      const resizeObserverInst = new ResizeObserver(() => {
        const { clientWidth, scrollWidth, clientHeight } = elementRef?.current || {};

        setItemContainerLayoutChange({
          elementRect: {
            scrollWidth,
            clientWidth,
            clientHeight,
          },
          timeChanged: Date.now(),
        });
      });

      elementRef?.current && resizeObserverInst.observe(elementRef?.current);
      return () =>
        elementRef?.current && resizeObserverInst.unobserve(elementRef?.current);
    }
  }, []);

  return [itemContainerLayoutChange];
}
