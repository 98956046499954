import { cloneDeep } from 'lodash';
import { BUTTON_TYPE } from 'src/components/WidgetMaker/utils/buttonConstant';

export const IMAGE_SHAPE_OVERRIDING_GLOBAL_STYLE = ['circle', 'arch', 'blob'];

export const getShapeStyles = (
  cardShape,
  cardWidth = 0,
  isFullWidthImgInCard = false
): Record<string, any>[] => {
  switch (cardShape) {
    case 'circle':
      return [{ className: '!tw-rounded-[50%]', style: {} }];
    case 'arch':
      return [
        {
          className: '!tw-rounded-es-[0px] !tw-rounded-ee-[0px]',
          style: {
            borderStartStartRadius: cardWidth ? `${cardWidth / 2}px` : '50%',
            borderStartEndRadius: cardWidth ? `${cardWidth / 2}px` : '50%',
          },
        },
      ];
    case 'blob':
      return [
        { className: '!tw-rounded-[30%_70%_70%_30%_/_30%_47%_53%_70%]' },
        { className: '!tw-rounded-[54%_46%_70%_30%_/_19%_79%_21%_81%]' },
        { className: '!tw-rounded-[87%_13%_60%_40%_/_30%_93%_7%_70%]' },
        { className: '!tw-rounded-[67%_33%_16%_84%_/_63%_52%_48%_37%]' },
        { className: '!tw-rounded-[72%_28%_69%_31%_/_67%_57%_43%_33%]' },
      ];
    default:
      return [
        {
          className: isFullWidthImgInCard
            ? 'tw-rounded-es-[0px] tw-rounded-ee-[0px]'
            : '',
          style: {},
        },
      ];
  }
};

export const getCurrentClass = (type) => {
  switch (type) {
    case 'zoomInCard':
    case 'zoomIn':
      return 'zoomInOnHover';
    case 'ambient':
      return 'ambientOnHover';
    case 'shadow':
      return 'containerShadow';
    default:
      return '';
  }
};

export const getOverlayWrapperStyles = (configData, isTextNameBelow = false) => {
  const { xAlignment, yAlignment, overlayOpacity } = cloneDeep(
    configData?.contentConfig?.textConfig || {}
  );
  const styles = {
    justifyContent: '',
    alignItems: '',
    background: isTextNameBelow ? '' : `rgba(0,0,0,${overlayOpacity / 100})`,
  };

  switch (yAlignment) {
    case 'top':
      styles.justifyContent = 'flex-start';
      break;
    case 'middle':
      styles.justifyContent = 'center';
      break;
    case 'bottom':
      styles.justifyContent = 'flex-end';
      break;
    default:
      styles.justifyContent = 'flex-end';
      break;
  }

  switch (xAlignment) {
    case 'left':
      styles.alignItems = 'flex-start';
      break;
    case 'center':
      styles.alignItems = 'center';
      break;
    case 'right':
      styles.alignItems = 'flex-end';
      break;
    default:
      styles.alignItems = 'flex-end';
      break;
  }

  return styles;
};

export const getTextContainerStyles = (configData) => {
  const { xAlignment } = cloneDeep(configData?.contentConfig?.textConfig || ({} as any));
  const styles = {
    justifyContent: 'flex-start',
  };
  let className = '';

  switch (xAlignment) {
    case 'left':
      className = '!tw-justify-start';
      break;
    case 'center':
      className = '!tw-justify-center';
      break;
    case 'right':
      className = '!tw-justify-end';
      break;
  }

  return { styles, className };
};

export const getOverlayTextStyles = (configData, buttonData = null) => {
  const { textSize, textCase, colorScheme, textThicness, xAlignment } = cloneDeep(
    configData?.contentConfig?.textConfig || ({} as any)
  );
  const styles = {
    fontSize: '',
    textTransform: textCase,
    lineHeight: '1',
    color: colorScheme?.text,
    background: '',
    width: 'auto',
    padding: '0px',
    textAlign: 'unset',
    fontWeight: '400',
  };

  switch (xAlignment) {
    case 'left':
      styles.textAlign = 'left';
      break;
    case 'center':
      styles.textAlign = 'center';
      break;
    case 'right':
      styles.textAlign = 'right';
      break;
  }

  // if (colorScheme.background) {
  //   (styles.background = colorScheme.background), (styles.width = '100%');
  //   styles.padding = '8px';
  //   styles.textAlign = 'center';
  // }

  switch (textSize) {
    case 'extraSmall':
      styles.fontSize = '12px';
      styles.lineHeight = '1';
      break;
    case 'small':
      styles.fontSize = '14px';
      styles.lineHeight = '1';
      break;
    case 'medium':
      styles.fontSize = '16px';
      styles.lineHeight = '1.2';
      break;
    case 'large':
      styles.fontSize = '18px';
      styles.lineHeight = '1.2';
      break;
    case 'extraLarge':
      styles.fontSize = '20px';
      styles.lineHeight = '1.2';
      break;
    default:
      styles.fontSize = '16px';
      styles.lineHeight = '1.1';
      break;
  }

  switch (textThicness) {
    case 'thin':
      styles.fontWeight = '300';
      break;
    case 'regular':
      styles.fontWeight = '400';
      break;
    case 'medium':
      styles.fontWeight = '500';
      break;
    case 'bold':
      styles.fontWeight = '700';
      break;
    default:
      styles.fontWeight = '400';
      break;
  }

  if (buttonData && buttonData.type !== BUTTON_TYPE.TEXTUAL) {
    styles.textAlign = 'center';
  }
  return styles;
};

export const getCardConfigurations = (cardFrameConfig) => {
  const isNonTextualElementPresent =
    cardFrameConfig?.contentConfig?.buttonConfig?.filter(
      (item) => item.isShow && item.type !== 'textual'
    )?.length ||
    cardFrameConfig?.contentConfig?.textConfig?.appearanceButton?.filter(
      (item) => item.isShow && item.type !== 'textual'
    )?.length;
  // If no button layout was selected
  const isButtonLayout = cardFrameConfig?.contentConfig?.buttonConfig?.filter(
    (item) => item.isShow
  )?.length;
  const isTextNameBelow = cardFrameConfig?.contentConfig?.textConfig?.type === 'below';
  const isCollectionNameVisible =
    !!cardFrameConfig?.contentConfig?.textConfig?.appearanceButton?.find(
      (buttonData) => buttonData.isShow
    );

  return {
    isNonTextualElementPresent,
    isButtonLayout,
    isTextNameBelow,
    isCollectionNameVisible,
  };
};
